<template>
    <b-tab>
        <template #title>
            <feather-icon
                    icon="UserCheckIcon"
                    size="21"
            />
            <strong>
                Szavazók
            </strong>
        </template>

        <b-overlay
                :show="loading"
                spinner-variant="primary"
                spinner-type="grow"
                rounded="sm"
        >

            <b-alert variant="warning" show>
                <div class="alert-body">
                    <feather-icon
                            icon="AlertTriangleIcon"
                            class="mr-50"
                    />
                    Azon felhasználók esetén, amelyeknél "Ismeretlen felhasználó" van kiírva, a migráció során nem
                    tudtuk őket átmigrálni, mert nem jelentkeztek be 2017. 01. 01. óta.
                </div>
            </b-alert>
            <!-- table -->
            <vue-good-table
                    mode="remote"
                    :columns="columns"
                    :rows="rows"
                    :rtl="direction"
                    :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
            >
                <div slot="emptystate">
                    <div class="vgt-center-align vgt-text-disabled">
                        {{ $t('table.list.empty') }}
                    </div>
                </div>
                <template
                        slot="table-row"
                        slot-scope="props"
                >

                    <!-- Column: Name -->
                    <span
                            v-if="props.column.field === 'user_id'"
                            class="text-nowrap"
                    >
                <span class="text-nowrap">{{
                    (props.row.user) ? props.row.user.name + ' (' + props.row.user.id + ')' : 'Ismeretlen felhasználó'
                    }}</span>
              </span>

                    <!-- Column: Common -->
                    <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
                </template>

                <!-- pagination -->
                <template
                        slot="pagination-bottom"
                        slot-scope="props"
                >
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ ((page - 1) * pageLength) + 1 }} +
            </span>
                            <b-form-select
                                    v-model="pageLength"
                                    :options="pages"
                                    class="mx-1"
                                    @change="handlePageChange"
                            />
                            <span class="text-nowrap "> a {{ dataFromServer.total }} találatból </span>
                        </div>
                        <div>
                            <b-pagination
                                    :value="1"
                                    :total-rows="dataFromServer.total"
                                    :per-page="dataFromServer.per_page"
                                    first-number
                                    last-number
                                    align="right"
                                    prev-class="prev-item"
                                    next-class="next-item"
                                    class="mt-1 mb-0"
                                    @change="handleChangePage"
                            >
                                <template #prev-text>
                                    <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                    />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>
        </b-overlay>
    </b-tab>
</template>

<script>
import {
    BTab,
    BOverlay,
    BAlert, BPagination, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import store from "@/store";
import {VueGoodTable} from "vue-good-table";

export default {
    components: {
        BTab,
        VueGoodTable,
        BPagination,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BAlert,
    },
    props: {
        entityId: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            role: 'poll',
            pageLength: 10,
            dir: false,
            pages: ['10', '20', '50'],
            columns: [
                {
                    label: 'Felhasználó',
                    field: 'user_id',
                    sortable: false,
                },
                {
                    label: 'Szavazás időpontja',
                    field: 'created_at',
                    sortable: false,
                    formatFn: this.$formatters.formatLaravelTimestampToDateTime,
                },
            ],
            rows: [],
            page: 1,
            dataFromServer: [],
            sortBy: {
                field: 'id',
                type: 'desc',
            },
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    created() {
        let storedPageLength = localStorage.getItem('pageLength')
        if (storedPageLength) {
            this.pageLength = storedPageLength
        }

        this.initData()
    },
    methods: {
        initData() {
            const searchParams = {
                page: parseInt(this.page),
                limit: parseInt(this.pageLength),
                query: this.searchTerm,
                columnFilters: {
                    poll_id: this.entityId,
                },
                sort: this.sortBy,
            }

            this.$store.dispatch('fetchPollAnswers', searchParams).then(response => {
                this.rows = response.data.entities.data
                this.dataFromServer = response.data.entities
                this.page = this.dataFromServer.current_page
                this.pageLength = parseInt(this.dataFromServer.per_page)
            })
        },
        handleChangePage(page) {
            this.page = page
            this.initData()
        },
        handlePageChange(active) {
            this.pageLength = active
            localStorage.setItem('pageLength', active)
            this.page = 1
            this.handleChangePage()
        },
        onSortChange(params) {
            this.page = 1
            this.sortBy = params[0]
            this.initData()
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
