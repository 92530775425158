var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-code',{attrs:{"title":"Szerkesztés"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Típus"}},[_c('validation-provider',{attrs:{"name":"típus","rules":"required","vid":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.typeOptions},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Kérdés"}},[_c('validation-provider',{attrs:{"name":"kérdés","rules":"required","vid":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"kérdés"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Kezdő dátum"}},[_c('validation-provider',{attrs:{"name":"kezdő dátum","rules":"required","vid":"start_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.flatPickRConfig},model:{value:(_vm.formData.start_date),callback:function ($$v) {_vm.$set(_vm.formData, "start_date", $$v)},expression:"formData.start_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Befejező dátum"}},[_c('validation-provider',{attrs:{"name":"befejező dátum","rules":"required","vid":"end_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.flatPickRConfig},model:{value:(_vm.formData.end_date),callback:function ($$v) {_vm.$set(_vm.formData, "end_date", $$v)},expression:"formData.end_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Címke"}},[_c('validation-provider',{attrs:{"name":"címke","vid":"tags"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","multiple":"","taggable":"","push-tags":"","options":_vm.tagOptions},model:{value:(_vm.formData.tags),callback:function ($$v) {_vm.$set(_vm.formData, "tags", $$v)},expression:"formData.tags"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"mező","vid":"result_is_public"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.result_is_public),callback:function ($$v) {_vm.$set(_vm.formData, "result_is_public", $$v)},expression:"formData.result_is_public"}},[_vm._v(" Publikus az eredmény? ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Link"}},[_c('validation-provider',{attrs:{"name":"link","rules":"","vid":"link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"readonly":"","state":errors.length > 0 ? false:null,"placeholder":"link"},model:{value:(_vm.formData.link),callback:function ($$v) {_vm.$set(_vm.formData, "link", $$v)},expression:"formData.link"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"float-right",attrs:{"variant":"info"},on:{"click":function($event){$event.preventDefault();return _vm.copyLink.apply(null, arguments)}}},[_c('feather-icon',{attrs:{"icon":"CopyIcon"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2 mb-3",attrs:{"xs":"12"}},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"info"},on:{"click":function($event){$event.preventDefault();return _vm.addNewOption.apply(null, arguments)}}},[_vm._v(" Új válasz ")]),_c('span',[_vm._v("Válaszok:")]),_c('br')],1)],1),_vm._l((_vm.formData.options),function(option){return _c('b-row',{key:option.id},[_c('b-col',{attrs:{"xs":"11"}},[_c('b-form-group',{attrs:{"label":"Válasz"}},[_c('validation-provider',{attrs:{"name":"answer","rules":"required","vid":"value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"válasz"},model:{value:(option.title),callback:function ($$v) {_vm.$set(option, "title", $$v)},expression:"option.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.formData.options.length > 1)?_c('b-col',{attrs:{"xs":"12","md":"1"}},[_c('b-button',{staticClass:"float-right mt-2",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.removeOption(option.id)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1)],1):_vm._e()],1)}),_c('b-row',[_c('b-col',{staticClass:"mt-1"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" Mentés ")]),_c('cancel-and-g-o-t-o-previous-page',{staticClass:"mr-2"})],1)],1)],2)],1)],1),_c('b-card-code',[_c('tabs',{ref:"tabs",attrs:{"entity-id":parseInt(this.$route.params.id),"results":_vm.results},on:{"refresh-tabs-data":_vm.refreshTabsData}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }