<template>
  <div>
    <b-card-code
        title="Szerkesztés"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="3">
              <b-form-group
                  label="Típus"
              >
                <validation-provider
                    #default="{ errors }"
                    name="típus"
                    rules="required"
                    vid="type"
                >
                  <v-select
                      v-model="formData.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="typeOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                  label="Kérdés"
              >
                <validation-provider
                    #default="{ errors }"
                    name="kérdés"
                    rules="required"
                    vid="title"
                >
                  <b-form-input
                      v-model="formData.title"
                      :state="errors.length > 0 ? false:null"
                      placeholder="kérdés"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                  label="Kezdő dátum"
              >
                <validation-provider
                    #default="{ errors }"
                    name="kezdő dátum"
                    rules="required"
                    vid="start_date"
                >
                  <flat-pickr
                      v-model="formData.start_date"
                      class="form-control"
                      :config="flatPickRConfig"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                  label="Befejező dátum"
              >
                <validation-provider
                    #default="{ errors }"
                    name="befejező dátum"
                    rules="required"
                    vid="end_date"
                >
                  <flat-pickr
                      v-model="formData.end_date"
                      class="form-control"
                      :config="flatPickRConfig"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                  label="Címke"
              >
                <validation-provider
                    #default="{ errors }"
                    name="címke"
                    vid="tags"
                >
                  <v-select
                      v-model="formData.tags"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      multiple
                      taggable
                      push-tags
                      :options="tagOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <validation-provider
                  #default="{ errors }"
                  name="mező"
                  vid="result_is_public"
              >
                <b-form-checkbox
                    v-model="formData.result_is_public"
                    :state="errors.length > 0 ? false:null"
                >
                  Publikus az eredmény?
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label="Link"
              >
                <validation-provider
                    #default="{ errors }"
                    name="link"
                    rules=""
                    vid="link"
                >
                  <b-input-group>
                    <b-form-input
                        v-model="formData.link"
                        readonly
                        :state="errors.length > 0 ? false:null"
                        placeholder="link"
                    />
                    <b-input-group-append>
                      <b-button
                          variant="info"
                          class="float-right"
                          @click.prevent="copyLink"
                      >
                        <feather-icon icon="CopyIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col xs="12" class="mt-2 mb-3">
              <b-button
                  variant="info"
                  class="float-right"
                  @click.prevent="addNewOption"
              >
                Új válasz
              </b-button>
              <span>Válaszok:</span><br/>
            </b-col>
          </b-row>
          <b-row
              v-for="option in formData.options"
              :key="option.id"
          >
            <!-- válaszok -->
            <b-col xs="11">
              <b-form-group
                  label="Válasz"
              >
                <validation-provider
                    #default="{ errors }"
                    name="answer"
                    rules="required"
                    vid="value"
                >
                  <b-form-input
                      v-model="option.title"
                      :state="errors.length > 0 ? false:null"
                      placeholder="válasz"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
                v-if="formData.options.length > 1"
                xs="12"
                md="1"
            >
              <b-button
                  variant="danger"
                  size="sm"
                  class="float-right mt-2"
                  @click.prevent="removeOption(option.id)"
              >
                <feather-icon
                    icon="Trash2Icon"
                />
              </b-button>
            </b-col>

          </b-row>
          <b-row>

            <!-- submit button -->
            <b-col class="mt-1">
              <b-button
                  variant="primary"
                  type="submit"
                  class="float-right"
                  @click.prevent="submitForm"
              >
                Mentés
              </b-button>
              <cancel-and-g-o-t-o-previous-page class="mr-2"/>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <b-card-code>
      <tabs
          ref="tabs"
          @refresh-tabs-data="refreshTabsData"
          :entity-id="parseInt(this.$route.params.id)"
          :results="results"
      />
    </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox, BInputGroup, BInputGroupAppend, BInputGroupAddon
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import tabs from '@/views/pages/_components/_tabs/_poll/update.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import {Hungarian} from 'flatpickr/dist/l10n/hu.js'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupAddon,
    vSelect,
    tabs,
    cancelAndGOTOPreviousPage,
    flatPickr,
  },
  data() {
    return {
      role: 'poll',
      baseUrl: process.env.VUE_APP_SERVER_BASE_URL,
      formData: {
        id: null,
        type: '',
        title: '',
        start_date: '',
        end_date: '',
        tags: [],
        result_is_public: false,
        options: [],
      },
      results: '',
      flatPickRConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        locale: Hungarian,
        allowInput: true
      },
      typeOptions: [],
      tagOptions: [],
      optionId: 1,
      optionSkeleton: { id: 0, title: '' },
      required,
    }
  },
  created() {
    this.$store.dispatch('fetchPoll', this.$route.params.id).then(response => {
      this.formData = response.data.entity
      this.results = response.data.results
      this.formData.id = this.$route.params.id
      this.formData.link = this.baseUrl+'/szavazasok/szavazas/'+this.formData.slug+'-p'+this.formData.id

      let options = [];
      Object.keys(response.data.entity.options).forEach(key => {
        options.push({
          id: this.optionId,
          title: response.data.entity.options[key]
        })
        this.optionId++
      })
      this.formData.options = options

      this.$store.dispatch('pollTypes').then(tyResponse => {
        this.typeOptions = tyResponse.data.values

        if (this.typeOptions.length) {
          Object.keys(this.typeOptions).forEach(key => {
            if (this.typeOptions[key].value === response.data.entity.type) {
              this.formData.type = this.typeOptions[key]
            }
          })
        }
      })

      let selectedTags = []

      // set tags
      this.$store.dispatch('fetchAllTagsForBootstrap').then(taResponse => {
        this.tagOptions = taResponse

        if (this.tagOptions.length && response.data.entity.tags && response.data.entity.tags.length) {
          Object.keys(response.data.entity.tags).forEach(tkey => {
            Object.keys(this.tagOptions).forEach(tokey => {
              if (response.data.entity.tags[tkey].id === this.tagOptions[tokey].value) {
                selectedTags.push(this.tagOptions[tokey])
              }
            })
          })
        }

        this.formData.tags = selectedTags
      })

    })
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let fd = {...this.formData}
          fd.type = this.formData.type.value

          this.$store.dispatch('updatePoll', fd).then(() => {
            this.$refs.tabs.refreshLog()
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'polls'})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
    addNewOption(){
      let sk = this.$helpers.cloneObject(this.optionSkeleton)
      sk.id = this.optionId++
      this.formData.options.push(sk)
    },
    removeOption(id){
      let options = []

      if (this.formData.options.length) {
        this.formData.options.forEach(option => {
          if (option.id !== id) {
            options.push(this.$helpers.cloneObject(option))
          }
        })
      }

      this.formData.options = options
    },
    copyLink(){
      navigator.clipboard.writeText(this.formData.link);
      this.$helpers.showSuccessToast('Vágólapra másolva: '+this.formData.link)
    },
    refreshTabsData() {
      this.$refs.tabs.refreshLog()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
