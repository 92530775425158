<template>
  <b-tabs content-class="mt-3">
    <results-tab
        ref="resultsTab"
        :results="results"
    />
    <voters-tab
        ref="votersTab"
        :entity-id="entityId"
    />
    <log-tab
        ref="tabLogTable"
        :entity-id="entityId"
        entity-type="poll"
    />
  </b-tabs>
</template>

<script>
import {
  BTabs,
} from 'bootstrap-vue'
import resultsTab from '@/views/pages/_components/_tab/_poll/_update/results.vue'
import votersTab from '@/views/pages/_components/_tab/_poll/_update/voters.vue'
import logTab from '@/views/pages/_components/_tab/_log/_list.vue'

export default {
  components: {
    BTabs,
    resultsTab,
    votersTab,
    logTab,
  },
  props: {
    entityId: {
      type: Number,
      required: true
    },
    results: {
      required: true
    }
  },
  methods: {
    refreshTabData() {
      this.$emit('refresh-tabs-data')
    },
    refreshLog() {
      this.$refs.tabLogTable.initData()
    },
    refreshResults() {
      this.$refs.resultsTab.initTab()
    },
  }
}
</script>

<style scoped>

</style>
